import './Line.css';

const Line = ({ overrideClass }) => {
    return (
        <hr
            className={'hr-footer-line ' + (overrideClass ? overrideClass : '')}
        />
    );
};

export default Line;
