import FeatureCardList from './featureCardList/FeatureCardList';
import './FeaturesPage.css';

const FeaturesPage = ({ featureContent }) => {
    return (
        <div className="feature-page">
            <div className="feature-page-content">
                <h1 className="feature-page-content-h1">
                    {featureContent.header}
                </h1>
                <FeatureCardList features={featureContent.features} />
            </div>
        </div>
    );
};

export default FeaturesPage;
