import './FeatureCard.css';

const FeatureCard = ({ content }) => {
    return (
        <div className="feature-card-div">
            <div className="feature-card-img-div">
                <img
                    className="feature-card-img"
                    src={content.imageUrl}
                    alt={content.name + ' img'}
                />
            </div>
            <h2 className="feature-card-h2">{content.name}</h2>
            <p className="feature-card-p">{content.description}</p>
        </div>
    );
};

export default FeatureCard;
