import { useState, useCallback } from 'react';
import MobilePricingUI from './mobile/MobilePricingUI';
import DesktopPricingUI from './desktop/DesktopPricingUI';
import PricingContext from './contexts/PricingContext';

const PricingUIType = ({ pricingContent }) => {
    const [selectedPlanDurations, setSelectedPlanDurations] = useState(
        pricingContent.planDurations
    );
    const [discountFactor, setDiscountFactor] = useState(0.9);
    const [customPrice, setCustomPrice] = useState(4500);
    const [runs, setRuns] = useState(100);
    const [allowedUsers, setAllowedUsers] = useState(1);
    const [selectedFeatures, setSelectedFeatures] = useState({
        extractUrl: false,
        uploadFiles: false,
        extractVideo: false,
        uploadAudioVideo: false,
        bundles: false,
        multilingual: false,
    });
    const [toastMessageList, setToastMessageList] = useState([]);

    /**
     * method to remove the message
     * @param key
     **/
    const removeMessage = useCallback((key) => {
        setToastMessageList((toastMessageList) =>
            toastMessageList.filter((message) => message.key !== key)
        );
    }, []);

    const getAllowedUserCostFactor = useCallback((newAllowedUsers) => {
        newAllowedUsers = parseInt(newAllowedUsers);
        if (newAllowedUsers === 1) {
            return 1;
        } else if (newAllowedUsers >= 2 && newAllowedUsers <= 7) {
            return 3;
        } else if (newAllowedUsers >= 8 && newAllowedUsers <= 24) {
            return 10;
        } else if (newAllowedUsers >= 25 && newAllowedUsers <= 50) {
            return 20;
        } else {
            return 0;
        }
    }, []);

    /**
     * update the customPrice after computation
     * @access private
     * @param newRuns
     **/
    const computeCost = useCallback(
        (newRuns, newAllowedUsers) => {
            let arr = Object.keys(selectedFeatures);
            let totalCost = newRuns * pricingContent.planFeatureCosts['runs'];
            arr.forEach((k) => {
                if (selectedFeatures[k]) {
                    totalCost += pricingContent.planFeatureCosts[k] * newRuns;
                }
            });
            totalCost *= getAllowedUserCostFactor(newAllowedUsers);
            setCustomPrice(totalCost);
        },
        [
            pricingContent.planFeatureCosts,
            selectedFeatures,
            getAllowedUserCostFactor,
        ]
    );

    /**
     * called when checkbox will be clicked
     * @param key - feature key
     * @param value - checked value
     **/
    function checkSelectedFeatures(key, value) {
        let newObj = selectedFeatures;
        newObj[key] = value;
        setSelectedFeatures(newObj);
        computeCost(runs, allowedUsers);
    }

    /**
     * used to update the runs via the number field
     * @param newRuns
     **/
    function updateRuns(newRuns) {
        setRuns(newRuns);
        computeCost(newRuns, allowedUsers);
    }

    /**
     * used to update the allowedUsers via the number field
     * @param newAllowedUsers
     **/
    function updateAllowedUsers(newAllowedUsers) {
        setAllowedUsers(newAllowedUsers);
        computeCost(runs, newAllowedUsers);
    }

    /**
     * Update the selected duration
     * @param key - featureKey
     **/
    const updateSelectedPlanDuration = useCallback(
        (key) => {
            let newObj = {
                ANNUAL: false,
                QUARTERLY: false,
                MONTHLY: false,
            };
            newObj[key] = true;
            setSelectedPlanDurations(newObj);
            let newDiscountFactor = pricingContent.planDurationDiscounts[key];
            setDiscountFactor(parseFloat(newDiscountFactor));
        },
        [pricingContent.planDurationDiscounts]
    );

    if (window.innerWidth <= 768) {
        return (
            <PricingContext.Provider
                value={{
                    pricingContent,
                    selectedPlanDurations,
                    updateSelectedPlanDuration,
                    discountFactor,
                    customPrice,
                    checkSelectedFeatures,
                    updateRuns,
                    runs,
                    allowedUsers,
                    updateAllowedUsers,
                    selectedFeatures,
                    toastMessageList,
                    removeMessage,
                    setToastMessageList,
                }}
            >
                <MobilePricingUI />
            </PricingContext.Provider>
        );
    } else {
        return (
            <PricingContext.Provider
                value={{
                    pricingContent,
                    selectedPlanDurations,
                    updateSelectedPlanDuration,
                    discountFactor,
                    customPrice,
                    checkSelectedFeatures,
                    updateRuns,
                    runs,
                    allowedUsers,
                    updateAllowedUsers,
                    selectedFeatures,
                    toastMessageList,
                    removeMessage,
                    setToastMessageList,
                }}
            >
                <DesktopPricingUI />
            </PricingContext.Provider>
        );
    }
};

export default PricingUIType;
