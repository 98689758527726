import './ContactUsButton.css';
import { useContext } from 'react';
import PricingContext from '../../contexts/PricingContext';
const ContactUsButton = () => {
    const { pricingContent } = useContext(PricingContext);

    return (
        <div className="contact-us-button-outer-div">
            <a
                href={pricingContent.contactUsButtonLink}
                target="_blank"
                rel="noreferrer"
                className="calendar-button-div-a"
            >
                <div className="contact-us-button-inner-div">
                    <img
                        src={pricingContent.contactUsButtonImg}
                        alt={pricingContent.contactUsButtonImg}
                    />
                    <h3 className="inline-block">Schedule Call</h3>
                </div>
            </a>
        </div>
    );
};

export default ContactUsButton;
