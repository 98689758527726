import MobilePlanFeature from '../planFeature/MobilePlanFeature';
import { useContext } from 'react';
import PricingContext from '../../contexts/PricingContext';

const MobilePlanFeatures = () => {
    const { pricingContent } = useContext(PricingContext);

    if (pricingContent.planFeatures && pricingContent.planFeatures.length > 0) {
        return pricingContent.planFeatures.map((planFeature) => (
            <MobilePlanFeature
                planFeature={planFeature}
                key={planFeature.key}
            />
        ));
    } else {
        return <></>;
    }
};

export default MobilePlanFeatures;
