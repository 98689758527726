import './SubPartContent.css';

const SubPartContent = ({ subPart }) => {
    function getContents(contents) {
        if (contents && contents.length > 0) {
            return contents.map((content, index) => (
                <p className="sub-part-content-div-p" key={index}>
                    {content}
                </p>
            ));
        } else {
            return <></>;
        }
    }

    function getQuote(quote, quoteBy) {
        if (quoteBy) {
            quoteBy = '- ' + quoteBy;
        } else {
            quoteBy = '';
        }

        if (quote) {
            return (
                <p className="sub-part-content-quote">{`"${quote}" ${quoteBy}`}</p>
            );
        } else {
            return <></>;
        }
    }

    return (
        <div className="sub-part-content-div">
            <h2>{subPart.header}</h2>
            {getQuote(subPart.quote, subPart.quoteBy)}
            {getContents(subPart.contents)}
        </div>
    );
};

export default SubPartContent;
