import './MobileNavbar.css';
import NavbarLinks from '../../../navbarlinks/NavbarLinks';
import ButtonLink from '../../../buttonLink/ButtonLink';

const MobileNavbar = ({
    isMobileNavbar,
    headerLinks,
    hideMobileNavbar,
    headerButtonLink,
}) => {
    function hideScrollBar() {
        document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    }

    function enableScrollBar() {
        document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    }

    function getCurrentClass() {
        if (isMobileNavbar) {
            hideScrollBar();
            return 'min-mobile-navbar expand-mobile-navbar';
        } else {
            enableScrollBar();
            return 'min-mobile-navbar';
        }
    }

    return (
        <div className={getCurrentClass()}>
            <div id="mobile-navbar-cross" onClick={hideMobileNavbar}>
                <i className="fa-solid fa-xmark"></i>
            </div>
            <NavbarLinks
                headerLinks={headerLinks}
                navbarLinksDivClass="navbar-links-div"
                navbarLinkDivClass="navbar-link-div"
                navbarLinkClass="navbar-link"
                hideMobileNavbar={hideMobileNavbar}
            />
            <ButtonLink
                text={headerButtonLink.buttonTitle}
                link={headerButtonLink.link}
                positionClass="button-link-position"
                hideMobileNavbar={hideMobileNavbar}
            />
        </div>
    );
};

export default MobileNavbar;
