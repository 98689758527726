import './DesktopFeatureList.css';
import { useContext } from 'react';
import PricingContext from '../../contexts/PricingContext';

const DesktopFeatureList = () => {
    const { pricingContent } = useContext(PricingContext);

    if (pricingContent.planFeatures && pricingContent.planFeatures.length > 0) {
        return pricingContent.planFeatures.map((feature) => (
            <div key={feature.key} className="desktop-feature-list-item">
                <p>{feature.name}</p>
            </div>
        ));
    } else {
        return <></>;
    }
};

export default DesktopFeatureList;
