import DesktopFeatureList from './DesktopFeatureList';
import './DesktopPlanFeatures.css';

const DesktopPlanFeatures = () => {
    return (
        <div className="pricing-plan-features-div">
            <div>
                <h2 className="pricing-plan-features-div-h2">FEATURES</h2>
                <DesktopFeatureList />
            </div>
        </div>
    );
};

export default DesktopPlanFeatures;
