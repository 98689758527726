import SectionSubHeader from '../sectionSubHeader/SectionSubHeader';

const SectionSubHeaders = ({ section }) => {
    if (section.subheaders && section.subheaders.length > 0) {
        return section.subheaders.map((subheader) => (
            <SectionSubHeader
                key={subheader.h3}
                h3={subheader.h3}
                ps={subheader.ps}
            />
        ));
    } else {
        return <></>;
    }
};

export default SectionSubHeaders;
