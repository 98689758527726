import './PlanDurationDiscount.css';
import { useCallback } from 'react';

const PlanDurationDiscount = ({
    planDurationType,
    planDurationDiscounts,
    selected,
}) => {
    const getPlanDurationDiscount = useCallback(() => {
        let discount =
            (1 - planDurationDiscounts[planDurationType]).toFixed(2) * 100;
        return `${discount}% Off`;
    }, [planDurationDiscounts, planDurationType]);

    if (planDurationType === 'ANNUAL' || planDurationType === 'QUARTERLY') {
        return (
            <div className="plan-duration-type-discount-div">
                <div
                    className={
                        'plan-duration-type-discount ' +
                        (selected ? 'selected-plan-duration-type-discount' : '')
                    }
                >
                    <span>{getPlanDurationDiscount()}</span>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default PlanDurationDiscount;
