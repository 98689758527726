import PricingContext from '../../contexts/PricingContext';
import PlanDurationType from './PlanDurationType';
import './PlanDurationTypes.css';
import { useContext } from 'react';

const PlanDurationTypes = () => {
    const { selectedPlanDurations } = useContext(PricingContext);

    if (
        selectedPlanDurations &&
        Object.keys(selectedPlanDurations).length > 0
    ) {
        let arr = Object.keys(selectedPlanDurations);
        return (
            <div className="plan-duration-types">
                {arr.map((planDurationType) => (
                    <PlanDurationType
                        key={planDurationType}
                        planDurationType={planDurationType}
                    />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default PlanDurationTypes;
