import ImgView from './imgView/ImgView';
import MultiViewContent from './multiViewContent/MultiViewContent';
import './MultiViewFeature.css';

const MultiViewFeature = ({ feature }) => {
    return (
        <div className="multi-view-feature-div">
            <div className="multi-view-feature-img-div">
                <ImgView feature={feature} />
                <div
                    style={{
                        backgroundColor: `${feature.color}`,
                    }}
                    className="multi-view-feature-color-div"
                ></div>
            </div>
            <MultiViewContent feature={feature} />
        </div>
    );
};

export default MultiViewFeature;
