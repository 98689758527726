import { Link } from 'react-router-dom';
import './NavbarLink.css';
import { getModifiedLink } from '../../../../utils/helper';

const NavbarLink = ({
    link,
    navbarLinkDivClass,
    navbarLinkClass,
    hideMobileNavbar,
}) => {

    return (
        <div className={navbarLinkDivClass}>
            <Link
                to={getModifiedLink(link)}
                className={navbarLinkClass}
                onClick={hideMobileNavbar}
            >
                {link}
            </Link>
        </div>
    );
};

export default NavbarLink;
