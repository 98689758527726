/**
 * Yellow Paper razorpay SDK
 * @since 11 Nov 2022
 * @copyright Yellow Paper 2022
 **/

/**
 * get the keys from the content.json according to hostname and gateway
 * @param paymentGateways
 * @param gateway
 **/
function getPaymentGatewayKeys(paymentGateways, gateway) {
    // return paymentGateways[gateway].production;
    if (paymentGateways && paymentGateways[gateway]) {
        if (window.location.hostname.includes('yellowpaper')) {
            return paymentGateways[gateway].production;
        } else {
            return paymentGateways[gateway].development;
        }
    }
    return null;
}

let isRzrpyChkoutScriptLoaded = false;

function loadScript(scriptUrl) {
    if (isRzrpyChkoutScriptLoaded) {
        return true;
    }

    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.onload = () => {
            isRzrpyChkoutScriptLoaded = true;
            resolve(true);
        };
        script.onerror = () => {
            isRzrpyChkoutScriptLoaded = false;
            reject(false);
        };
        document.body.appendChild(script);
    });
}

/**
 * generic method to hit the POST API
 **/
async function postData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

/**
 * function to hit the orders API and the get the order details from the server
 * @param ordersAPIUrl
 * @param orderIdRequest
 **/
async function callOrdersAPI(ordersAPIUrl, orderIdRequest = {}) {
    const response = await postData(ordersAPIUrl, orderIdRequest);
    // console.log('ORDER ID RESPONSE', response);
    if (response && response.status && response.message) {
        return response;
    }
    return null;
}

async function callOrdersVerifyAPI(paymentVerificationUrl, verifyRequest) {
    const response = await postData(paymentVerificationUrl, verifyRequest);
    // console.log(response);
    if (response && response.status && response.status === 200) {
        return response;
    }
    return null;
}

/**
 * function to display the razorpay UI
 * @param keys
 * @param amount in paise
 * @param currency 'INR'
 * @param orderId
 **/
async function displayRazorpayUI(keys, amount, currency, orderId, description) {
    return new Promise((resolve, reject) => {
        const options = {
            key: keys.key_id, // Enter the Key ID generated from the Dashboard
            amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: currency,
            name: 'Yellow Paper',
            description: description,
            image: './Logo.svg',
            order_id: orderId, //get this from orders API from backend
            notes: {
                address: 'Razorpay Corporate Office',
            },
            theme: {
                color: '#1e96fc',
            },
            timeout: 300,
            handler: function (response) {
                // console.log('SUCCESS: ', response);
                resolve(response);
            },
            modal: {
                ondismiss: function () {
                    reject({
                        error: {
                            code: 'CHECKOUT_FORM_CLOSED',
                            description: 'Checkout form closed by the user',
                        },
                    });
                },
                confirm_close: true,
            },
        };
        const paymentObj = new window.Razorpay(options);
        paymentObj.open();

        paymentObj.on('payment.failed', function (response) {
            // console.log('error: ', response);
            reject(response);
        });
    });
}

/**
 * function to handle the razorpay payment interface
 * - load the razorpay frontend SDK
 * - get the orderId and receipt from server
 * - show the razorpay interface
 * @param paymentGateways
 * @param gateway
 * @param orderIdRequest
 * @return
 * {
 *      "message": "",
 *      "sdkStatusCode": ""
 * }
 *
 * sdkStatusCodes
 * - MISSING_PAYMENT_GATEWAY_KEYS
 * - MISSING_RAZORPAY_SDK
 * - FAILED_ORDER_ID_GENERATION
 * - RAZORPAY_HANDLER_EXCEPTION
 *
 * - PAYMENT_VERIFICATION_SUCCESSFUL
 * - PAYMENT_VERIFICATION_FAILED
 **/
async function razorpayHandler(paymentGateways, gateway, orderIdRequest) {
    try {
        const keys = getPaymentGatewayKeys(paymentGateways, gateway);
        if (keys === null) {
            return {
                message: 'Unable to find Razorpay Payment Interface keys',
                sdkStatusCode: 'MISSING_PAYMENT_GATEWAY_KEYS',
            };
        }

        const isScriptLoaded = await loadScript(
            paymentGateways[gateway].scriptUrl
        );
        if (!isScriptLoaded) {
            return {
                message: 'Unable to load the Razorpay Checkout SDK',
                sdkStatusCode: 'MISSING_RAZORPAY_SDK',
            };
        }

        const orderIdResponse = await callOrdersAPI(
            paymentGateways[gateway].ordersAPIUrl,
            orderIdRequest
        );

        if (orderIdResponse === null) {
            return {
                message: 'Unable to initiate razorpay payment',
                sdkStatusCode: 'FAILED_ORDER_ID_GENERATION',
            };
        } else if (
            orderIdResponse.status === 400 &&
            orderIdResponse.message.includes('exceeds maximum amount')
        ) {
            return {
                message: orderIdResponse.message,
                sdkStatusCode: 'FAILED_ORDER_ID_GENERATION',
            };
        } else if (
            !orderIdResponse.razorpay_response ||
            !orderIdResponse.razorpay_response.id ||
            !orderIdResponse.razorpay_response.receipt
        ) {
            return {
                message: 'Unable to find the razorpay payment order id',
                sdkStatusCode: 'FAILED_ORDER_ID_GENERATION',
            };
        }
        // console.log('orderID', orderIdResponse.razorpay_response.id);

        const razorpayUiResponse = await displayRazorpayUI(
            keys,
            orderIdRequest.amount + '',
            orderIdRequest.currency,
            orderIdResponse.razorpay_response.id,
            `Selected Plan: ${orderIdRequest.planType} (${orderIdRequest.planDurationType})`
        );
        // console.log('razorpayUiResponse', razorpayUiResponse);
        if (
            razorpayUiResponse &&
            razorpayUiResponse.razorpay_payment_id &&
            razorpayUiResponse.razorpay_order_id &&
            razorpayUiResponse.razorpay_signature
        ) {
            razorpayUiResponse['order_receipt'] =
                orderIdResponse.razorpay_response.receipt;
            const verifyResponse = await callOrdersVerifyAPI(
                paymentGateways[gateway].paymentVerificationUrl,
                razorpayUiResponse
            );
            if (verifyResponse != null && verifyResponse.status === 200) {
                return {
                    message:
                        'Payment is successful and Verified! Thanks for choosing Yellow Paper!',
                    sdkStatusCode: 'PAYMENT_VERIFICATION_SUCCESSFUL',
                };
            } else {
                return {
                    message:
                        'Your payment has been failed, Please try again !!',
                    sdkStatusCode: 'PAYMENT_VERIFICATION_FAILED',
                };
            }
        }
    } catch (e) {
        // console.log('razorpayHandler', e);
        if (e.error && e.error.code && e.error.description) {
            switch (e.error.code) {
                case 'CHECKOUT_FORM_CLOSED':
                    return {
                        message:
                            'Your order payment has been cancelled, Please try again !!',
                        sdkStatusCode: 'RAZORPAY_HANDLER_EXCEPTION',
                    };
                case 'BAD_REQUEST_ERROR':
                    return {
                        message: e.error.description,
                        sdkStatusCode: 'RAZORPAY_HANDLER_EXCEPTION',
                    };
                default:
                    return {
                        message: 'Unable to load the Razorpay SDK',
                        sdkStatusCode: 'RAZORPAY_HANDLER_EXCEPTION',
                    };
            }
        } else {
            return {
                message: 'Unable to load the Razorpay SDK',
                sdkStatusCode: 'RAZORPAY_HANDLER_EXCEPTION',
            };
        }
    }
}

export { razorpayHandler };
