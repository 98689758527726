import './WorkCard.css';

const WorkCard = ({ card }) => {
    return (
        <div className="work-card-div">
            <img src={card.imagePath} alt="card img" />
            <p>{card.info}</p>
        </div>
    );
};

export default WorkCard;
