import MultiViewFeatures from './multiViewFeatures/MultiViewFeatures';
import './FeaturesSection.css';

const FeaturesSection = ({ content, persClass }) => {
    return (
        <div className={"features-section-div " + persClass}>
            <h2 className="features-section-h2">{content.h2}</h2>
            <h1 className="features-section-h1">{content.h1}</h1>
            <MultiViewFeatures features={content.features} />
        </div>
    );
};

export default FeaturesSection;
