import VerticalLine from '../verticalLine/VerticalLine';
import './VerticalLines.css';

const VerticalLines = ({ count, lineClass }) => {
    function getStyleBasedOnCount() {
        let currWidthPercent = 100;
        if (window.innerWidth >= 1300) {
            currWidthPercent = 50;
        } else if (window.innerWidth >= 1205) {
            currWidthPercent = 60;
        } else if (window.innerWidth >= 1024) {
            currWidthPercent = 70;
        } else if (window.innerWidth >= 769) {
            currWidthPercent = 80;
        }

        if (count === 4) {
            return {
                display: 'inline-block',
                width: currWidthPercent / count + '%',
            };
        }
    }

    function getMultiDivs() {
        let arr = Array.from(Array(count).keys());

        return arr.map((num) => (
            <div
                key={num}
                style={getStyleBasedOnCount()}
                className="vertical-lines-single-div"
            >
                <VerticalLine lineClass={lineClass} />
            </div>
        ));
    }

    return <div>{getMultiDivs()}</div>;
};

export default VerticalLines;
