import './MobileHeader.css';
import MobileNavbar from './navbar/MobileNavbar';
import React from 'react';
import { useState } from 'react';
import ProductLogo from '../../productlogo/ProductLogo';

const MobileHeader = ({ headerLinks, whiteHeader, headerButtonLink }) => {
    const [isMobileNavbar, setIsMobileNavbar] = useState(false);

    function showMobileNavbar() {
        setIsMobileNavbar(true);
    }

    function hideMobileNavbar() {
        setIsMobileNavbar(false);
    }

    return (
        <>
            <div
                id="mobile-header"
                className={whiteHeader ? 'white-background' : ''}
            >
                <ProductLogo overrideImgClass="header-product-logo" />
                <span id="mobile-header-right-icon" onClick={showMobileNavbar}>
                    <i className="fa-solid fa-bars"></i>
                </span>
            </div>
            <MobileNavbar
                headerLinks={headerLinks}
                isMobileNavbar={isMobileNavbar}
                hideMobileNavbar={hideMobileNavbar}
                headerButtonLink={headerButtonLink}
            />
        </>
    );
};

export default MobileHeader;
