import './ToastMessage.css';
import { useEffect } from 'react';

const ToastMessage = ({
    toastMessage,
    removeMessage,
    hideTimeInMilliSeconds,
}) => {
    useEffect(() => {
        let timer = setTimeout(() => {
            removeMessage(toastMessage.key);
        }, hideTimeInMilliSeconds);

        return () => {
            clearTimeout(timer);
        };
    }, [removeMessage, toastMessage.key, hideTimeInMilliSeconds]);

    function getDynamicIconClass(type) {
        if (type === 'Success') {
            return 'fa-2x fa-solid fa-circle-check';
        } else {
            return 'fa-2x fa-solid fa-circle-exclamation';
        }
    }

    function getDynamicColorClass(type) {
        if (type === 'Success') {
            return 'success-toast-color';
        } else {
            return 'failure-toast-color';
        }
    }

    function closeHandler() {
        removeMessage(toastMessage.key);
    }

    return (
        <div
            className={
                'toast-message-outer-div ' +
                getDynamicColorClass(toastMessage.title)
            }
        >
            <button className="toast-message-cross" onClick={closeHandler}>
                <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="toast-message-img-div inline-block">
                <i className={getDynamicIconClass(toastMessage.title)}></i>
            </div>
            <div className="toast-message-inner-div inline-block">
                <p>{toastMessage.title}</p>
                <p>{toastMessage.message}</p>
            </div>
        </div>
    );
};

export default ToastMessage;
