import ProductLogo from '../productlogo/ProductLogo';
import Line from './line/Line';
import NavbarLinks from '../navbarlinks/NavbarLinks';
import './Footer.css';
import FooterBottom from './footerBottom/FooterBottom';
import FooterInfo from './footerInfo/FooterInfo';
// import ButtonLink from '../buttonLink/ButtonLink';
import ProductHunt from '../productHunt/ProductHunt';

const Footer = ({ footer }) => {
    return (
        <div className="footer-div">
            <div className="footer-content">
                <div>
                    <Line />
                    <div className="footer-content-logo-div">
                        <ProductLogo overrideImgClass="footer-product-logo" />
                        <ProductHunt productHunt={footer.productHunt} />
                    </div>
                    <div className="footer-content-links-div">
                        <NavbarLinks
                            headerLinks={footer.links}
                            navbarLinksDivClass="footer-navbar-links"
                            navbarLinkDivClass="footer-navbar-link"
                            navbarLinkClass="footer-navbar-link-a"
                        />
                    </div>
                    <Line overrideClass="mobile-line" />
                    <FooterInfo footer={footer} />
                    <Line overrideClass="desktop-line" />
                </div>
                <FooterBottom footer={footer} />
            </div>
        </div>
    );
};

export default Footer;
