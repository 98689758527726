import SubPartImgView from '../subPartImgView/SubPartImgView';
import './AboutSubPart.css';
import SubPartContent from '../subPartContent/SubPartContent';

const AboutSubPart = ({ subPart }) => {
    return (
        <div className="about-sub-part">
            <SubPartImgView subPart={subPart} />
            <SubPartContent subPart={subPart} />
        </div>
    );
};

export default AboutSubPart;
