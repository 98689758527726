import SectionParas from '../sectionParas/SectionParas';
import './SectionSubHeader.css';

const SectionSubHeader = ({ h3, ps }) => {
    function getConditionalH3() {
        if (h3) {
            return <h3 className="section-sub-header-h3">{h3}</h3>;
        } else {
            return <></>;
        }
    }

    return (
        <>
            {getConditionalH3()}
            <SectionParas ps={ps} />
        </>
    );
};

export default SectionSubHeader;
