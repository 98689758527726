import './ContactPage.css';
import ContactForm from './contactForm/ContactForm';
const ContactPage = ({ contactContent }) => {
    return (
        <div className="contact-page-div">
            <div className="contact-page-content">
                <h1 className="contact-page-content-h1">{contactContent.h1}</h1>
                <h2 className="contact-page-content-h2">{contactContent.h2}</h2>
                <ContactForm contactContent={contactContent} />
            </div>
        </div>
    );
};

export default ContactPage;
