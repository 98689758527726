import DesktopPlanFeatures from './desktopPlanFeatures/DesktopPlanFeatures';
import DesktopPricingPlans from './desktopPricingPlans/DesktopPricingPlans';
import PlanDurationTypes from '../common/planDurationTypes/PlanDurationTypes';
import ToastMessages from '../../../common/toastMessages/ToastMessages';
import ContactUsButton from '../common/ContactUsButton/ContactUsButton';
import PricingContext from '../contexts/PricingContext';
import { useContext } from 'react';

const DesktopPricingUI = () => {
    const { toastMessageList, removeMessage } = useContext(PricingContext);

    return (
        <>
            <div className="desktop-pricing-plans">
                <div className="desktop-pricing-plan-content">
                    <PlanDurationTypes />
                    <DesktopPlanFeatures />
                    <DesktopPricingPlans />
                </div>
            </div>
            <ToastMessages
                toastMessageList={toastMessageList}
                removeMessage={removeMessage}
                hideTimeInMilliSeconds={5000}
            />
            <ContactUsButton />
        </>
    );
};

export default DesktopPricingUI;
