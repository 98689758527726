import RedirectButton from './RedirectButton/RedirectButton';
import PaymentButton from './PaymentButton/PaymentButton';

const PricingButton = ({ pricingPlan, discountedPrice }) => {
    switch (pricingPlan.type) {
        case 'Free Trial':
            return <RedirectButton />;
        case 'Basic':
        case 'Premium':
        case 'Customised':
            return (
                <PaymentButton
                    pricingPlan={pricingPlan}
                    discountedPrice={discountedPrice}
                />
            );
        default:
            return <></>;
    }
};

export default PricingButton;
