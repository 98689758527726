import { useState } from 'react';
import FormInput from '../formInput/FormInput.js';
import ToastMessages from '../../../common/toastMessages/ToastMessages';
import './ContactForm.css';
import { useLocation } from 'react-router-dom';

const ContactForm = ({ contactContent }) => {
    let { state } = useLocation();

    const [formObj, setFormObj] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
    });
    const [toastMessageList, setToastMessageList] = useState([]);

    function removeMessage(key) {
        if (key != null) {
            setToastMessageList(
                toastMessageList.filter((message) => message.key !== key)
            );
        }
    }

    async function postDataToServer(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    }

    function handleChange(event) {
        const newObj = { ...formObj };
        newObj[event.target.name] = event.target.value;
        setFormObj(newObj);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const response = await postDataToServer(
            contactContent.postContactMessage,
            formObj
        ).catch((e) => {
            setToastMessageList([
                ...toastMessageList,
                {
                    title: 'Failure',
                    message: contactContent.failureMessage,
                    errorResponse: e,
                    key: new Date().toISOString(),
                },
            ]);
        });
        if (response && response.status) {
            if (response.status === 200) {
                setToastMessageList([
                    ...toastMessageList,
                    {
                        title: 'Success',
                        message: contactContent.successMessage,
                        key: new Date().toISOString(),
                    },
                ]);
            } else if (
                response.status === 409 &&
                response.message.includes('Repetitive data entry')
            ) {
                setToastMessageList([
                    ...toastMessageList,
                    {
                        title: 'Success',
                        message: contactContent.repeatMessage,
                        key: new Date().toISOString(),
                    },
                ]);
            } else {
                setToastMessageList([
                    ...toastMessageList,
                    {
                        title: 'Failure',
                        message: contactContent.failureMessage,
                        errorResponse: response,
                        key: new Date().toISOString(),
                    },
                ]);
            }
        }
        setFormObj({
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            message: '',
        });
    }

    return (
        <>
            <form className="contact-form" onSubmit={handleSubmit}>
                <FormInput
                    type="text"
                    label="First Name *"
                    value={formObj.firstname}
                    handleChange={handleChange}
                    name="firstname"
                    required={true}
                    maxLength={30}
                    placeholder="Jatin"
                />
                <FormInput
                    type="text"
                    label="Last Name"
                    value={formObj.lastname}
                    handleChange={handleChange}
                    name="lastname"
                    maxLength={30}
                    placeholder="Ahuja"
                />
                <FormInput
                    type="email"
                    label="Email *"
                    value={formObj.email}
                    handleChange={handleChange}
                    name="email"
                    required={true}
                    maxLength={50}
                    placeholder="example@gmail.com"
                />
                <FormInput
                    type="tel"
                    label="Phone"
                    value={formObj.phone}
                    handleChange={handleChange}
                    name="phone"
                    maxLength={20}
                    pattern={'\\+[0-9 \\-]+'}
                    placeholder={'+91-888 888 8888'}
                />
                <FormInput
                    type="textarea"
                    label="Leave us a message... *"
                    value={state?.msg ? state?.msg : formObj.message}
                    handleChange={handleChange}
                    name="message"
                    required={true}
                    maxLength={200}
                    placeholder="Yellow Paper is awesome !!"
                />
                <div className="contact-form-submit-div">
                    <input
                        type="submit"
                        value="Submit"
                        className="contact-form-submit-button"
                    />
                </div>
            </form>
            <ToastMessages
                toastMessageList={toastMessageList}
                removeMessage={removeMessage}
                hideTimeInMilliSeconds={3000}
            />
        </>
    );
};

export default ContactForm;
