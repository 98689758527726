import MultiViewBulletPoints from '../bulletPoints/MultiViewBulletPoints';
import './MultiViewContent.css';
import ButtonLink from '../../../../common/buttonLink/ButtonLink';

const MultiViewContent = ({ feature }) => {
    function conditionalContent() {
        if (feature.p) {
            return <p className="multi-view-content-div-p">{feature.p}</p>;
        } else if (feature.bulletPoints && feature.bulletPoints.length > 0) {
            return (
                <MultiViewBulletPoints bulletPoints={feature.bulletPoints} />
            );
        } else {
            return <></>;
        }
    }

    return (
        <div className="multi-view-content-div">
            <h1 className="multi-view-content-div-h1">{feature.h1}</h1>
            <h2 className="multi-view-content-div-h2">{feature.h3}</h2>
            {conditionalContent()}
            <div className="multi-view-content-button-div">
                {feature.buttonLink &&
                    <ButtonLink
                        text={feature.buttonLink.buttonTitle}
                        link={feature.buttonLink.link}
                        overrideButtonClass="button-link-feature-section"
                    />
                }
            </div>
        </div>
    );
};

export default MultiViewContent;
