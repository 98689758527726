import './FooterIcon.css';

const FooterIcon = ({ iconLink }) => {
    return (
        <div className="footer-icon-div">
            <a
                href={iconLink.link}
                className="footer-icon-a"
                target="_blank"
                rel="noreferrer"
            >
                <i className={`fa-brands fa-2x fa-${iconLink.type}`} />
            </a>
        </div>
    );
};

export default FooterIcon;
