import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from '../homepage/Homepage';
import PageNotFound from '../notfound/PageNotFound';
import Page from '../pages/Page';
import contentJson from '../../content.json';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<Homepage contentJson={contentJson} />}
                />
                <Route
                    path="/:pageType"
                    element={<Page contentJson={contentJson} />}
                />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
