import './Video.css';

const Video = ({ videoSources }) => {
    function getVideoSource() {
        if (videoSources && videoSources.length > 0) {
            return videoSources.map((videoSource) => (
                <source
                    src={videoSource.link}
                    type={videoSource.type}
                    key={videoSource.type}
                />
            ));
        } else {
            return <></>;
        }
    }

    return (
        <div className="intro-section-video-div">
            <video className="intro-section-video" autoPlay muted controls loop>
                {getVideoSource()}
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default Video;
