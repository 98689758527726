import FeaturesPage from './featuresPage/FeaturesPage';
import PricingPage from './pricingPage/PricingPage';
import PageNotFound from '../notfound/PageNotFound';
import AboutPage from './aboutPage/AboutPage';
import ContactPage from './contactPage/ContactPage';
import Policy from './policy/Policy';
import Homepage from '../homepage/Homepage';

const PageRouter = ({ pageType, contentJson }) => {
    switch (pageType) {
        case '/Features':
            return <FeaturesPage featureContent={contentJson.featurePage} />;
        case '/Pricing':
            return <PricingPage pricingContent={contentJson.pricingPage} />;
        case '/About':
            return <AboutPage aboutContent={contentJson.aboutPage} />;
        case '/Contact':
            return <ContactPage contactContent={contentJson.contactUsPage} />;
        case '/Privacy-Policy':
            return <Policy policyContent={contentJson.privacyPolicy} />;
        case '/Terms-and-Conditions':
            return <Policy policyContent={contentJson.termsAndConditions} />;
        case '/Refund-Policy':
            return <Policy policyContent={contentJson.refundPolicy} />;
        case '/AskAI':
            return <Homepage contentJson={contentJson}/>;
        default:
            return <PageNotFound />;
    }
};

export default PageRouter;
