import './SectionParas.css';

const SectionParas = ({ ps }) => {
    function replaceAndReturnPara(para) {
        if (para && para.endsWith('info@yellowpaper.co.in')) {
            para = para.replace('info@yellowpaper.co.in', '');
            return (
                <>
                    {para}
                    <a href="mailto:info@yellowpaper.co.in">
                        info@yellowpaper.co.in
                    </a>
                    .
                </>
            );
        } else if (para && para.endsWith('https://razorpay.com')) {
            para = para.replace('https://razorpay.com', '');
            return (
                <>
                    {para}
                    <a
                        href="https://razorpay.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://razorpay.com
                    </a>
                    .
                </>
            );
        } else {
            return <>{para}</>;
        }
    }

    if (ps && ps.length > 0) {
        return ps.map((para) => (
            <p key={para} className="section-para">
                {replaceAndReturnPara(para)}
            </p>
        ));
    } else {
        return <></>;
    }
};

export default SectionParas;
