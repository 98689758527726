import MultiViewBulletPoint from '../bulletPoint/MultiViewBulletPoint';
import './MultiViewBulletPoints.css';

const MultiViewBulletPoints = ({ bulletPoints }) => {
    return (
        <div className="multi-view-bullet-points">
            {bulletPoints.map((bulletPoint) => (
                <MultiViewBulletPoint
                    key={bulletPoint.point}
                    point={bulletPoint.point}
                    imgUrl={bulletPoint.imgUrl}
                />
            ))}
        </div>
    );
};

export default MultiViewBulletPoints;
