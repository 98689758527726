import ToastMessage from './toastMessage/ToastMessage';
import './ToastMessages.css';

const ToastMessages = ({
    toastMessageList,
    removeMessage,
    hideTimeInMilliSeconds,
}) => {
    if (toastMessageList && toastMessageList.length > 0) {
        return (
            <div className="toast-messages">
                {toastMessageList.map((toastMessage) => (
                    <ToastMessage
                        key={toastMessage.key}
                        toastMessage={toastMessage}
                        removeMessage={removeMessage}
                        hideTimeInMilliSeconds={hideTimeInMilliSeconds}
                    />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default ToastMessages;
