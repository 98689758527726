import WhoCard from '../whoCard/WhoCard';

const WhoCards = ({ cards }) => {
    if (cards && cards.length > 0) {
        return (
            <div className="who-cards-div">
                {cards.map((card) => (
                    <WhoCard key={card.header} card={card} />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default WhoCards;
