import PolicySections from './sections/PolicySections';
import './Policy.css';

const Policy = ({ policyContent }) => {
    return (
        <div className="policy-div">
            <div className="policy-content">
                <h1>{policyContent.h1}</h1>
                <PolicySections sections={policyContent.sections} />
            </div>
        </div>
    );
};

export default Policy;
