import { useState } from 'react';
import ToastMessages from '../../../common/toastMessages/ToastMessages';
import './NewsletterForm.css';

const NewsletterForm = ({ content }) => {
    const [email, setEmail] = useState('');
    const [toastMessageList, setToastMessageList] = useState([]);

    function removeMessage(key) {
        if (key != null) {
            setToastMessageList(
                toastMessageList.filter((message) => message.key !== key)
            );
        }
    }

    function handleChange(e) {
        setEmail(e.target.value);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const response = await postDataToServer(content.postSubscribe, {
            email,
        }).catch((e) => {
            setToastMessageList([
                ...toastMessageList,
                {
                    title: 'Failure',
                    message: content.failureMessage,
                    errorResponse: e,
                    key: new Date().toISOString(),
                },
            ]);
        });
        if (response && response.status) {
            if (response.status === 200) {
                setToastMessageList([
                    ...toastMessageList,
                    {
                        title: 'Success',
                        message: content.successMessage,
                        key: new Date().toISOString(),
                    },
                ]);
            } else if (
                response.status === 409 &&
                response.message.includes('already subscribed')
            ) {
                setToastMessageList([
                    ...toastMessageList,
                    {
                        title: 'Success',
                        message: response.message,
                        errorResponse: response,
                        key: new Date().toISOString(),
                    },
                ]);
            } else {
                setToastMessageList([
                    ...toastMessageList,
                    {
                        title: 'Failure',
                        message: response.message,
                        errorResponse: response,
                        key: new Date().toISOString(),
                    },
                ]);
            }
        }
        setEmail('');
    }

    async function postDataToServer(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="newsletter-form">
                <label>
                    <p className="newsletter-email-p">
                        Enter your email here <sup>*</sup>
                    </p>
                    <input
                        type="email"
                        value={email}
                        onChange={handleChange}
                        required
                        className="newsletter-email-input"
                        maxLength={50}
                        placeholder="example@gmail.com"
                    />
                </label>
                <input
                    type="submit"
                    value="Subscribe"
                    className="newsletter-subscribe-button"
                />
            </form>
            <ToastMessages
                toastMessageList={toastMessageList}
                removeMessage={removeMessage}
                hideTimeInMilliSeconds={3000}
            />
        </>
    );
};

export default NewsletterForm;
