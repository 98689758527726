import './WhoCard.css';

const WhoCard = ({ card }) => {
    return (
        <div className="who-flip-card">
            <div className="who-flip-inner-card">
                <div className="who-flip-card-front">
                    <img
                        src={card.imgUrl}
                        alt="card img"
                        className="who-flip-card-front-img"
                    />
                    <h2 className="who-flip-card-front-h2">{card.header}</h2>
                </div>
                <div className="who-flip-card-back">
                    <p className="who-flip-card-back-p">{card.info}</p>
                </div>
            </div>
        </div>
    );
};

export default WhoCard;
