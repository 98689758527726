import SectionSubHeaders from '../sectionSubHeaders/SectionSubHeaders';
import SectionSubHeader from '../sectionSubHeader/SectionSubHeader';
import './PolicySection.css';

const PolicySection = ({ section }) => {
    return (
        <div className="policy-section">
            <h2 className="policy-section-h2">{section.h2}</h2>
            <SectionSubHeader h3={section.h3} ps={section.ps} />
            <SectionSubHeaders section={section} />
        </div>
    );
};

export default PolicySection;
