import FeatureCard from '../featureCard/FeatureCard';

const FeatureCardList = ({ features }) => {
    if (features && features.length > 0) {
        return (
            <div>
                {features.map((feature) => (
                    <FeatureCard key={feature.name} content={feature} />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default FeatureCardList;
