import WhoCards from './whoCards/WhoCards';
import './WhoSection.css';

const WhoSection = ({ content }) => {
    return (
        <div className="who-section">
            <div className="who-section-content">
                <h1 className="who-section-content-h1">{content.header}</h1>
                <WhoCards cards={content.cards} />
            </div>
        </div>
    );
};

export default WhoSection;
