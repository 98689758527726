import AboutSubPart from '../subPart/AboutSubPart';

const AboutSubParts = ({ subParts }) => {
    if (subParts && subParts.length > 0) {
        return subParts.map((subPart) => (
            <AboutSubPart key={subPart.header} subPart={subPart} />
        ));
    } else {
        return <></>;
    }
};

export default AboutSubParts;
