const RedirectButton = () => {
    return (
        <a
            href="https://console.yellowpaper.co.in/login"
            target="_blank"
            rel="noreferrer"
            className="calendar-button-div-a"
        >
            <button className="payment-button payment-button-space">
                Get Started
            </button>
        </a>
    );
};

export default RedirectButton;
