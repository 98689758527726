import MobilePlanValues from '../planValues/MobilePlanValues';
import './MobilePlanFeature.css';

const MobilePlanFeature = ({ planFeature }) => {
    return (
        <div className="mobile-plan-feature">
            <p className="mobile-plan-feature-name">{planFeature.name}</p>
            <MobilePlanValues
                featureKey={planFeature.key}
                planValues={planFeature.planValues}
            />
            <hr className="mobile-plan-feature-hr" />
        </div>
    );
};

export default MobilePlanFeature;
