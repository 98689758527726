import { useState } from 'react';
import './PlanCheckbox.css';

const PlanCheckbox = ({ featureKey, checkSelectedFeatures }) => {
    const [checked, setChecked] = useState(false);

    function handleChange() {
        let newValue = !checked;

        checkSelectedFeatures(featureKey, newValue);
        setChecked(newValue);
    }

    return (
        <div className="plan-value-div">
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                className={
                    'plan-checkbox ' + (checked ? 'plan-selected-checkbox' : '')
                }
            />
        </div>
    );
};

export default PlanCheckbox;
