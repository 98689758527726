import './ImgView.css';

const ImgView = ({ feature }) => {
    function getImg1Class() {
        return `img-view${feature.viewType}-first-img`;
    }

    function getImg2Class() {
        return `img-view${feature.viewType}-second-img`;
    }

    function getImg2Conditionally() {
        if (feature.img2) {
            return (
                <img
                    src={feature.img2}
                    alt="feature img"
                    className={getImg2Class()}
                />
            );
        }
    }

    return (
        <div className="img-view-div">
            <img
                src={feature.img1}
                alt="feature img"
                className={getImg1Class()}
            />
            {getImg2Conditionally()}
        </div>
    );
};

export default ImgView;
