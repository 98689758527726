import './PlanNumber.css';

const PlanNumber = ({ featureKey, inputNumber, updateInputNumber }) => {
    function handleChange(e) {
        if (
            isNaN(e.target.value) ||
            e.target.value === '0' ||
            e.target.value.includes('.') ||
            e.target.value < 0
        ) {
            return;
        }
        if (featureKey === 'runs' && e.target.value > 500) {
            return;
        }
        if (featureKey === 'allowedUsers' && e.target.value > 50) {
            return;
        }
        let newInputNumber = e.target.value;
        updateInputNumber(newInputNumber);
    }

    return (
        <div className="plan-value-div plan-value-div-number">
            <input
                type="text"
                pattern="[0-9]*"
                value={inputNumber}
                onChange={handleChange}
                required={true}
            />
        </div>
    );
};

export default PlanNumber;
