import './DesktopPricingPlan.css';
import DesktopPlanChecklist from './planChecklist/DesktopPlanChecklist';
import PricingButton from '../../common/pricingButton/PricingButton';
import { useCallback, useContext } from 'react';
import PricingContext from '../../contexts/PricingContext';

const DesktopPricingPlan = ({ pricingPlan, index }) => {
    const { customPrice, discountFactor } = useContext(PricingContext);

    const getPriceByPlanType = useCallback(() => {
        if (pricingPlan.type === 'Customised') {
            return customPrice;
        } else {
            return pricingPlan.price;
        }
    }, [pricingPlan.type, customPrice, pricingPlan.price]);

    const getDiscountedPrice = useCallback(() => {
        let price = parseFloat(getPriceByPlanType());
        let discountedPrice = price * discountFactor;
        discountedPrice = discountedPrice.toFixed(2);
        return discountedPrice;
    }, [getPriceByPlanType, discountFactor]);

    return (
        <div
            className={
                'pricing-plan-div dotted-border ' +
                (pricingPlan.selected ? 'selected-pricing-plan' : '')
            }
        >
            <div className="pricing-plan-content-div">
                <h2 className="pricing-plan-type">{pricingPlan.type}</h2>
                <h4 className="pricing-plan-desc">{pricingPlan.description}</h4>
                <h1 className="pricing-plan-price">
                    {pricingPlan.currency}
                    {getDiscountedPrice()}
                </h1>
                <p className="pricing-plan-interval">
                    {pricingPlan.timeInterval}
                </p>
                <PricingButton
                    pricingPlan={pricingPlan}
                    discountedPrice={getDiscountedPrice()}
                />
                <DesktopPlanChecklist index={index} />
            </div>
        </div>
    );
};

export default DesktopPricingPlan;
