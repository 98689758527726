import MobilePlanFeatures from './planFeatures/MobilePlanFeatures';
import MobilePlanHeaders from './planHeaders/MobilePlanHeaders';
import PlanDurationTypes from '../common/planDurationTypes/PlanDurationTypes';
import ToastMessages from '../../../common/toastMessages/ToastMessages';
import ContactUsButton from '../common/ContactUsButton/ContactUsButton';
import PricingContext from '../contexts/PricingContext';
import { useContext } from 'react';

const MobilePricingUI = () => {
    const { toastMessageList, removeMessage } = useContext(PricingContext);

    return (
        <>
            <div className="mobile-pricing-ui">
                <div className="mobile-pricing-ui-content">
                    <PlanDurationTypes />
                    <MobilePlanHeaders />
                    <MobilePlanFeatures />
                </div>
            </div>
            <ToastMessages
                hideTimeInMilliSeconds={5000}
                toastMessageList={toastMessageList}
                removeMessage={removeMessage}
            />
            <ContactUsButton />
        </>
    );
};

export default MobilePricingUI;
