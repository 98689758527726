import DesktopHeader from './desktop/DesktopHeader';
import MobileHeader from './mobile/MobileHeader';
import { useEffect, useState } from 'react';

const Header = ({ headerLinks, headerButtonLink }) => {
    const [whiteHeader, setWhiteHeader] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > 10 && !whiteHeader) {
                setWhiteHeader(true);
            }
            if (currentScrollY <= 10 && whiteHeader) {
                setWhiteHeader(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [whiteHeader]);

    return (
        <>
            <DesktopHeader
                headerLinks={headerLinks}
                whiteHeader={whiteHeader}
                headerButtonLink={headerButtonLink}
            />
            <MobileHeader
                headerLinks={headerLinks}
                whiteHeader={whiteHeader}
                headerButtonLink={headerButtonLink}
            />
        </>
    );
};

export default Header;
