import MultiViewFeature from '../multiViewFeature/MultiViewFeature';

const MultiViewFeatures = ({ features }) => {
    if (features && features.length > 0) {
        return (
            <div className="multi-view-features">
                {features.map((feature) => (
                    <MultiViewFeature key={feature.h1} feature={feature} />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default MultiViewFeatures;
