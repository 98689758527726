import './FormInput.css';

const FormInput = ({
    type,
    label,
    value,
    handleChange,
    name,
    required = false,
    maxLength,
    pattern,
    placeholder = '',
}) => {
    function getInputControlViaType() {
        if (type === 'textarea') {
            return (
                <textarea
                    name={name}
                    required={required}
                    value={value}
                    onChange={handleChange}
                    rows={3}
                    maxLength={maxLength}
                    placeholder={placeholder}
                ></textarea>
            );
        } else {
            return (
                <input
                    type={type}
                    value={value}
                    onChange={handleChange}
                    name={name}
                    required={required}
                    maxLength={maxLength}
                    pattern={pattern}
                    placeholder={placeholder}
                />
            );
        }
    }

    return (
        <div
            className={
                type === 'textarea'
                    ? 'form-input-outer-div-textarea'
                    : 'form-input-outer-div'
            }
        >
            <label>
                <div className="form-input-label-div">{label}</div>
                <div className="form-input-inner-div">
                    {getInputControlViaType()}
                </div>
            </label>
        </div>
    );
};

export default FormInput;
