import PlanValue from '../../../common/planValue/PlanValue';
import { useContext } from 'react';
import PricingContext from '../../../contexts/PricingContext';
const DesktopPlanChecklist = ({ index }) => {
    const { pricingContent } = useContext(PricingContext);

    if (pricingContent.planFeatures && pricingContent.planFeatures.length > 0) {
        return pricingContent.planFeatures.map((planFeature) => (
            <PlanValue
                key={planFeature.planValues[index].key}
                featureKey={planFeature.key}
                planValue={planFeature.planValues[index].value}
            />
        ));
    } else {
        return <></>;
    }
};

export default DesktopPlanChecklist;
