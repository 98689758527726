import FooterIcons from '../footerIcons/FooterIcons';
import './FooterBottom.css';
import { Link } from 'react-router-dom';

const FooterBottom = ({ footer }) => {
    return (
        <div>
            <FooterIcons iconLinks={footer.iconLinks} />
            <div className="footer-copyright-div">
                {footer.copyrightInfo}{' '}
                <Link to="/Privacy-Policy" className="privacy-policy-link">
                    Privacy Policy
                </Link>{' '}
                |{' '}
                <Link
                    to="/Terms-and-Conditions"
                    className="terms-and-conditions-link"
                >
                    Terms and Conditions
                </Link>{' '}
                |{' '}
                <Link to="/Refund-Policy" className="refund-policy-link">
                    Refund Policy
                </Link>
            </div>
        </div>
    );
};

export default FooterBottom;
