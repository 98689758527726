import PricingContext from '../../contexts/PricingContext';
import PlanCheckbox from './PlanCheckbox';
import PlanNumber from './PlanNumber';
import './PlanValue.css';
import { useContext } from 'react';

const PlanValue = ({ featureKey, planValue }) => {
    const {
        runs,
        updateRuns,
        allowedUsers,
        updateAllowedUsers,
        checkSelectedFeatures,
    } = useContext(PricingContext);

    if (planValue === 'Yes') {
        return (
            <div className="plan-value-div plan-value-div-check">
                <i className="fa-solid fa-check"></i>
            </div>
        );
    } else if (planValue === 'No') {
        return (
            <div className="plan-value-div plan-value-div-xmark">
                <i className="fa-solid fa-xmark"></i>
            </div>
        );
    } else if (planValue === 'number') {
        if (featureKey === 'runs') {
            return (
                <PlanNumber
                    featureKey={featureKey}
                    inputNumber={runs}
                    updateInputNumber={updateRuns}
                />
            );
        } else if (featureKey === 'allowedUsers') {
            return (
                <PlanNumber
                    featureKey={featureKey}
                    inputNumber={allowedUsers}
                    updateInputNumber={updateAllowedUsers}
                />
            );
        } else {
            return <></>;
        }
    } else if (planValue === 'checkbox') {
        return (
            <PlanCheckbox
                featureKey={featureKey}
                checkSelectedFeatures={checkSelectedFeatures}
            />
        );
    } else {
        return (
            <div className="plan-value-div plan-value-div-normal">
                {planValue}
            </div>
        );
    }
};

export default PlanValue;
