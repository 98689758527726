import LearnMore from './learnmore/LearnMore';
import './IntroSection.css';
import Video from './video/Video';

const IntroSection = ({ introContent }) => {
    return (
        <div className="homepage-bg">
            <div className="homepage-bg-content">
                <h2>{introContent.h2}</h2>
                <h1>{introContent.h1}</h1>
                <Video videoSources={introContent.videoSources} />
                <h3>{introContent.h3}</h3>
                <LearnMore to={introContent.buttonLink} />
            </div>
        </div>
    );
};

export default IntroSection;
