import './PricingPage.css';
import PricingUIType from './PricingUIType';

const PricingPage = ({ pricingContent }) => {
    return (
        <div className="pricing-section">
            <div className="pricing-section-content">
                <h1 className="pricing-section-h1">{pricingContent.header}</h1>
                <PricingUIType pricingContent={pricingContent} />
            </div>
        </div>
    );
};

export default PricingPage;
