import './SubPartImgView.css';

const SubPartImgView = ({ subPart }) => {
    return (
        <div className="sub-part-img-view">
            <div className="sub-part-img-view-img-div">
                <img src={subPart.imageUrl} alt="about sub part img" />
            </div>
            <div
                className="about-sub-part-color-div"
                style={{ backgroundColor: subPart.color }}
            ></div>
        </div>
    );
};

export default SubPartImgView;
