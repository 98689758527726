import { razorpayHandler } from './YellowPaperRazorpaySDK';
import './PaymentButton.css';
import { useCallback, useContext } from 'react';
import PricingContext from '../../../contexts/PricingContext';

const PaymentButton = ({ pricingPlan, discountedPrice }) => {
    const {
        selectedPlanDurations,
        pricingContent,
        runs,
        allowedUsers,
        selectedFeatures,
        setToastMessageList,
    } = useContext(PricingContext);

    const getSelectedPlanDurationType = useCallback(() => {
        let filteredPlanDuration = Object.keys(selectedPlanDurations).filter(
            (property) => selectedPlanDurations[property] === true
        );
        return filteredPlanDuration[0];
    }, [selectedPlanDurations]);

    const filterCurrentPlanFeature = useCallback(
        (key) => {
            if (
                pricingContent.planFeatures &&
                Array.isArray(pricingContent.planFeatures)
            ) {
                const planFeature = pricingContent.planFeatures.filter(
                    (featureObj) => featureObj.key === key
                );
                return planFeature[0];
            }
        },
        [pricingContent.planFeatures]
    );

    const getSelectedPlanRun = useCallback(() => {
        switch (pricingPlan.type) {
            case 'Basic':
                return filterCurrentPlanFeature('runs').planValues[1].value;
            case 'Premium':
                return filterCurrentPlanFeature('runs').planValues[2].value;
            case 'Customised':
                return parseInt(runs);
            default:
                return null;
        }
    }, [pricingPlan.type, filterCurrentPlanFeature, runs]);

    const getSelectedPlanAllowedUsers = useCallback(() => {
        switch (pricingPlan.type) {
            case 'Basic':
                return filterCurrentPlanFeature('allowedUsers').planValues[1]
                    .value;
            case 'Premium':
                return filterCurrentPlanFeature('allowedUsers').planValues[2]
                    .value;
            case 'Customised':
                return parseInt(allowedUsers);
            default:
                return null;
        }
    }, [pricingPlan.type, filterCurrentPlanFeature, allowedUsers]);

    const getPlanSelectedFeatures = useCallback(() => {
        if (pricingPlan.type === 'Customised') {
            return selectedFeatures;
        } else if (
            pricingPlan.type === 'Basic' ||
            pricingPlan.type === 'Premium'
        ) {
            let index = -1;
            if (pricingPlan.type === 'Basic') {
                index = 1;
            } else if (pricingPlan.type === 'Premium') {
                index = 2;
            }
            const obj = {};
            pricingContent.planFeatures.forEach((planFeature) => {
                if (planFeature.key !== 'runs') {
                    obj[planFeature.key] =
                        planFeature.planValues[index].value === 'Yes'
                            ? true
                            : false;
                }
            });
            return obj;
        }
        return {};
    }, [pricingPlan.type, pricingContent.planFeatures, selectedFeatures]);

    /**
     * method to call the razorpay and handle the response
     **/
    const callYellowPaperRazorpaySDK = useCallback(
        async (orderIdRequest) => {
            const sdkResponse = await razorpayHandler(
                pricingContent.paymentGateways,
                'razorpay',
                orderIdRequest
            );
            if (sdkResponse && sdkResponse.sdkStatusCode) {
                let uiMessage = '';
                let uiStatus = 'Failure';
                switch (sdkResponse.sdkStatusCode) {
                    case 'PAYMENT_VERIFICATION_SUCCESSFUL':
                        uiMessage = sdkResponse.message;
                        uiStatus = 'Success';
                        break;
                    default:
                        uiStatus = 'Failure';
                        uiMessage = sdkResponse.message;
                        break;
                }
                setToastMessageList((toastMessageList) => [
                    ...toastMessageList,
                    {
                        title: uiStatus,
                        message: uiMessage,
                        key: new Date().toISOString(),
                    },
                ]);
            }
        },
        [pricingContent.paymentGateways, setToastMessageList]
    );

    const handleClick = useCallback(() => {
        let planDurationType = getSelectedPlanDurationType();
        let newPrice = discountedPrice;
        switch (planDurationType) {
            case 'QUARTERLY':
                newPrice = newPrice * 3;
                break;
            case 'ANNUAL':
                newPrice = newPrice * 12;
                break;
            default:
                break;
        }

        if (Math.round(newPrice * 100) > 50000000) {
            window.open(
                pricingContent.contactUsButtonLink,
                '_blank',
                'noreferrer'
            );
            return;
        }

        let runs = getSelectedPlanRun();
        let allowedUsers = getSelectedPlanAllowedUsers();
        if (isNaN(runs) || isNaN(allowedUsers)) {
            setToastMessageList((toastMessageList) => [
                ...toastMessageList,
                {
                    title: 'Failure',
                    message: "Number of Users and Runs can't be empty",
                    key: new Date().toISOString(),
                },
            ]);
            return;
        }

        const orderIdRequest = {
            planType: pricingPlan.type,
            planDurationType: getSelectedPlanDurationType(),
            runs: getSelectedPlanRun(),
            selectedFeatures: getPlanSelectedFeatures(),
            amount: Math.round(newPrice * 100),
            currency: 'INR',
            allowedUsers: getSelectedPlanAllowedUsers(),
        };
        // console.log('UI Request', orderIdRequest);
        callYellowPaperRazorpaySDK(orderIdRequest);
    }, [
        pricingPlan.type,
        getSelectedPlanDurationType,
        getSelectedPlanRun,
        getPlanSelectedFeatures,
        discountedPrice,
        getSelectedPlanAllowedUsers,
        callYellowPaperRazorpaySDK,
        setToastMessageList,
        pricingContent.contactUsButtonLink,
    ]);

    return (
        <button onClick={handleClick} className="payment-button">
            Get Started
        </button>
    );
};

export default PaymentButton;
