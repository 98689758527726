import FooterIcon from '../footerIcon/FooterIcon';
import './FooterIcons.css';

const FooterIcons = ({ iconLinks }) => {
    if (iconLinks && iconLinks.length > 0) {
        return (
            <div className="footer-icons-div">
                {iconLinks.map((iconLink) => (
                    <FooterIcon key={iconLink.type} iconLink={iconLink} />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default FooterIcons;
