import './FooterInfo.css';

const FooterInfo = ({ footer }) => {
    return (
        <div className="footer-info-div">
            <p>
                <a
                    href={footer.addressLink}
                    target="_blank"
                    rel="noreferrer"
                    className="footer-info-link"
                >
                    {footer.address1}
                </a>
            </p>
            <p id="footer-info-address2-p">
                <a
                    href={footer.addressLink}
                    target="_blank"
                    rel="noreferrer"
                    className="footer-info-link"
                >
                    {footer.address2}
                </a>
            </p>
            <p>
                <a
                    href={'mailto:' + footer.contactEmail}
                    className="footer-info-link"
                >
                    {footer.contactEmail}
                </a>
            </p>
            <p>
                <a
                    href={'tel:' + footer.contactNumber}
                    className="footer-info-link"
                >
                    {footer.contactNumber}
                </a>
            </p>
        </div>
    );
};

export default FooterInfo;
