import './HowItWorksSection.css';
import WorkCards from './cards/WorkCards';
import { useEffect, useState } from 'react';
import VerticalLines from './verticalLines/VerticalLines';

const HowItWorksSection = ({ content }) => {
    const [lineClass, setLineClass] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (
                currentScrollY > 1100 &&
                lineClass !== 'stretch-vertical-line'
            ) {
                setLineClass(() => 'stretch-vertical-line');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [lineClass]);

    return (
        <div className="how-it-works-section">
            <h2 className="how-it-works-h2">{content.heading}</h2>
            <h1 className="how-it-works-h1">{content.h1}</h1>
            <div className="how-it-works-p-div">
                <p>{content.desc1}</p>
                <p>{content.desc2}</p>
            </div>
            <div className="how-it-works-img-div">
                <img
                    src={content.productConceptPath}
                    alt="product concept img"
                />
            </div>
            {content.cards.length > 0 && <VerticalLines count={4} lineClass={lineClass} />}
            <WorkCards cards={content.cards} />
        </div>
    );
};

export default HowItWorksSection;
