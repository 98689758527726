import './AboutPage.css';
import AboutSubParts from './subParts/AboutSubParts';

const AboutPage = ({ aboutContent }) => {
    return (
        <div className="about-page-main">
            <div className="about-page-content">
                <h1>{aboutContent.header}</h1>
                <AboutSubParts subParts={aboutContent.subParts} />
            </div>
        </div>
    );
};

export default AboutPage;
