import NavbarLink from './navbarlink/NavbarLink';
import './NavbarLinks.css';

const NavbarLinks = ({
    headerLinks,
    navbarLinksDivClass,
    navbarLinkDivClass,
    navbarLinkClass,
    hideMobileNavbar,
}) => {
    if (headerLinks && headerLinks.length > 0) {
        return (
            <div className={navbarLinksDivClass}>
                {headerLinks.map((link) => (
                    <NavbarLink
                        link={link}
                        key={link}
                        navbarLinkDivClass={navbarLinkDivClass}
                        navbarLinkClass={navbarLinkClass}
                        hideMobileNavbar={hideMobileNavbar}
                    />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default NavbarLinks;
