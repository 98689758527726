import PolicySection from '../section/PolicySection';

const PolicySections = ({ sections }) => {
    if (sections && sections.length > 0) {
        return sections.map((section) => (
            <PolicySection key={section.h2} section={section} />
        ));
    } else {
        return <></>;
    }
};

export default PolicySections;
