import { Link, useLocation } from 'react-router-dom';
import './ButtonLink.css';
import { checkExternalLink, getModifiedLink } from '../../../utils/helper';
import contentJson from "../../../content.json";

const ButtonLink = ({
    text,
    link,
    positionClass,
    overrideButtonClass,
    hideMobileNavbar,
}) => {
    function handleClick() {
        if (!hideMobileNavbar) {
            return;
        }
        hideMobileNavbar();
    }

    const {pathname} = useLocation();

    return (
        text && 
        <div className={positionClass}>
            {checkExternalLink(link) ?
                <a href={link} target='_blank'>
                    <button className={'button-link ' + overrideButtonClass}>
                        {text}
                    </button>
                </a>
            
            : 
                <Link to={getModifiedLink(link)} state={{ msg: pathname === '/AskAI' && contentJson.askAiPage.contactUsMessage }} onClick={handleClick}>
                    <button className={'button-link ' + overrideButtonClass}>
                        {text}
                    </button>
                </Link>
            }
        </div>
    );
};

export default ButtonLink;
