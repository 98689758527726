import productHuntCss from './ProductHunt.module.css';

const ProductHunt = ({ productHunt }) => {
    return (
        <a
            href={productHunt.link}
            target="_blank"
            rel="noreferrer"
            className={productHuntCss.productHuntLink}
        >
            <img
                src={productHunt.img.link}
                alt={productHunt.img.alt}
                className={productHuntCss.productHuntImg}
            />
        </a>
    );
};

export default ProductHunt;
