import NewsletterForm from './form/NewsletterForm';
import './NewsletterSection.css';

const NewsletterSection = ({ content }) => {
    return (
        <div className="newsletter-section">
            <img src={content.imgUrl} alt="newletter img" />
            <h1>{content.header1}</h1>
            <NewsletterForm content={content} />
        </div>
    );
};

export default NewsletterSection;
