import PricingButton from '../../common/pricingButton/PricingButton';
import './MobilePlanHeader.css';
import { useCallback, useContext } from 'react';
import PricingContext from '../../contexts/PricingContext';

const MobilePlanHeader = ({ pricingPlan }) => {
    const { customPrice, discountFactor } = useContext(PricingContext);

    const getPriceByPlanType = useCallback(() => {
        if (pricingPlan.type === 'Customised') {
            return customPrice;
        } else {
            return pricingPlan.price;
        }
    }, [pricingPlan.type, customPrice, pricingPlan.price]);

    const getDiscountedPrice = useCallback(() => {
        let price = parseFloat(getPriceByPlanType());
        let discountedPrice = price * discountFactor;
        discountedPrice = discountedPrice.toFixed(2);
        return discountedPrice;
    }, [getPriceByPlanType, discountFactor]);

    return (
        <div className="mobile-plan-header">
            <h2 className="mobile-plan-header-type">{pricingPlan.type}</h2>
            <p className="mobile-plan-header-desc">{pricingPlan.description}</p>
            <h1 className="mobile-plan-header-h1">
                {pricingPlan.currency}
                {getDiscountedPrice()}
            </h1>
            <p className="mobile-plan-header-time-interval">
                {pricingPlan.timeInterval}
            </p>
            <PricingButton
                pricingPlan={pricingPlan}
                discountedPrice={getDiscountedPrice()}
            />
        </div>
    );
};

export default MobilePlanHeader;
