import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import IntroSection from "./introSection/IntroSection";
import HowItWorksSection from "./howItWorksSection/HowItWorksSection";
import FeaturesSection from "./featuresSection/FeaturesSection";
import WhoSection from "./whoSection/WhoSection";
import NewsletterSection from "./newsletterSection/NewsletterSection";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Homepage = ({ contentJson }) => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      // if not a hash link, scroll to top
      window.scrollTo(0, 0);
    } else {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [pathname, hash, key]);

  return (
    <div>
      <Header
        headerLinks={contentJson.headerLinks}
        headerButtonLink={
          pathname === "/AskAI"
            ? contentJson.askAiButtonLink
            : contentJson.headerButtonLink
        }
      />
      <IntroSection
        introContent={
          pathname === "/AskAI"
            ? contentJson.askAiPage.introSection
            : contentJson.homepage.introSection
        }
      />
      {pathname !== "/AskAI" && (
        <HowItWorksSection content={contentJson.homepage.howItWorksSection} />
      )}
      {pathname === "/AskAI" && (
        <FeaturesSection content={contentJson.askAiPage.personalizeSection} persClass="personalize-section"/>
      )}
      <FeaturesSection
        content={
          pathname === "/AskAI"
            ? contentJson.askAiPage.featuresSection
            : contentJson.homepage.featuresSection
        }
        persClass="use-case-section"
      />
      {pathname !== "/AskAI" && (
        <WhoSection content={contentJson.homepage.whoIsItForSection} />
      )}
      <NewsletterSection content={contentJson.homepage.newsletterSection} />
      {pathname !== "/AskAI" && <Footer footer={contentJson.footer} />}
    </div>
  );
};

export default Homepage;
