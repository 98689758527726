import { Link } from 'react-router-dom';
import './LearnMore.css';
import contentJson from '../../../../content.json';
import { useLocation } from 'react-router-dom';

const LearnMore = ({ to, path }) => {
    const {pathname} = useLocation();

    return (
        <div className="learn-more-div">
            <Link to={to ? to?.link : '/'} state={{ msg: pathname === '/AskAI' && contentJson.askAiPage.contactUsMessage }}>
                <button className="learn-more-button">{to?.buttonTitle}</button>
            </Link>
        </div>
    );
};

export default LearnMore;
