import DesktopPricingPlan from '../desktopPricingPlan/DesktopPricingPlan';
import { useContext } from 'react';
import PricingContext from '../../contexts/PricingContext';

const DesktopPricingPlans = () => {
    const { pricingContent } = useContext(PricingContext);

    if (pricingContent.pricingPlans && pricingContent.pricingPlans.length > 0) {
        return pricingContent.pricingPlans.map((pricing, index) => (
            <DesktopPricingPlan
                key={pricing.type}
                pricingPlan={pricing}
                index={index}
            />
        ));
    } else {
        return <></>;
    }
};

export default DesktopPricingPlans;
