import PlanValue from '../../common/planValue/PlanValue';
const MobilePlanValues = ({ featureKey, planValues }) => {
    if (planValues && planValues.length > 0) {
        return planValues.map((planValue) => (
            <PlanValue
                key={planValue.key}
                featureKey={featureKey}
                planValue={planValue.value}
            />
        ));
    } else {
        return <></>;
    }
};

export default MobilePlanValues;
