import WorkCard from './card/WorkCard';
import './WorkCards.css';

const WorkCards = ({ cards }) => {
    return (
        <div className="work-cards">
            {cards.map((card) => (
                <WorkCard key={card.info} card={card} />
            ))}
        </div>
    );
};

export default WorkCards;
