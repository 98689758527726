import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import PageRouter from './PageRouter';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Page = ({ contentJson }) => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        if (hash === '') {
            // if not a hash link, scroll to top
            window.scrollTo(0, 0);
        } else {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [pathname, hash, key]);

    return (
        <div>
            <Header
                headerLinks={contentJson.headerLinks}
                headerButtonLink={pathname === "/AskAI" ? contentJson.askAiButtonLink : contentJson.headerButtonLink}
            />
            <PageRouter pageType={pathname} contentJson={contentJson} />
            <Footer footer={contentJson.footer} />
        </div>
    );
};

export default Page;
