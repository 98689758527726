import './ProductLogo.css';
import { Link } from 'react-router-dom';

const ProductLogo = ({ overrideImgClass }) => {
    return (
        <Link to="/">
            <img
                src="Logo.svg"
                alt="product logo"
                className={`product-logo-img ${overrideImgClass}`}
            />
        </Link>
    );
};

export default ProductLogo;
