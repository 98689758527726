import PlanDurationDiscount from './PlanDurationDiscount';
import './PlanDurationType.css';
import { useCallback, useContext } from 'react';
import PricingContext from '../../contexts/PricingContext';

const PlanDurationType = ({ planDurationType }) => {
    const {
        selectedPlanDurations,
        updateSelectedPlanDuration,
        pricingContent,
    } = useContext(PricingContext);

    const handleClick = useCallback(
        (e) => {
            updateSelectedPlanDuration(e.target.value);
        },
        [updateSelectedPlanDuration]
    );

    return (
        <div className="plan-duration-type-div">
            <PlanDurationDiscount
                planDurationType={planDurationType}
                planDurationDiscounts={pricingContent.planDurationDiscounts}
                selected={selectedPlanDurations[planDurationType]}
            />
            <button
                className={
                    'plan-type ' +
                    (selectedPlanDurations[planDurationType]
                        ? 'selected-plan-type'
                        : '')
                }
                onClick={handleClick}
                value={planDurationType}
            >
                {planDurationType}
            </button>
        </div>
    );
};

export default PlanDurationType;
