import NavbarLinks from '../../navbarlinks/NavbarLinks';
import './DesktopHeader.css';
import ProductLogo from '../../productlogo/ProductLogo';
import ButtonLink from '../../buttonLink/ButtonLink';

const DesktopHeader = ({ headerLinks, whiteHeader, headerButtonLink }) => {
    return (
        <div
            id="desktop-header"
            className={whiteHeader ? 'white-background' : ''}
        >
            <ProductLogo overrideImgClass="header-product-logo" />

            <div className="desktop-header-right-div">
                <NavbarLinks
                    headerLinks={headerLinks}
                    navbarLinksDivClass="navbar-links-div-desktop"
                    navbarLinkDivClass="navbar-link-div-desktop"
                    navbarLinkClass="navbar-link-desktop"
                />

                <ButtonLink
                    text={headerButtonLink.buttonTitle}
                    link={headerButtonLink.link}
                    positionClass="button-link-position"
                    overrideButtonClass="override-header-button-link"
                />
            </div>  
        </div>
    );
};

export default DesktopHeader;
