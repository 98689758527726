export function getModifiedLink(link="") {
    if (link && link.toString().charAt(0) !== '/') {
        return '/' + link;
    } else {
        return link;
    }
}

export function checkExternalLink(link) {
    return link?.toString().includes("http");
}