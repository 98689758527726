import MobilePlanHeader from '../planHeader/MobilePlanHeader';
import './MobilePlanHeaders.css';
import { useContext } from 'react';
import PricingContext from '../../contexts/PricingContext';

const MobilePlanHeaders = () => {
    const { pricingContent } = useContext(PricingContext);

    if (pricingContent.pricingPlans && pricingContent.pricingPlans.length > 0) {
        return (
            <div className="mobile-plan-headers">
                {pricingContent.pricingPlans.map((pricingPlan) => (
                    <MobilePlanHeader
                        key={pricingPlan.type}
                        pricingPlan={pricingPlan}
                    />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
};

export default MobilePlanHeaders;
