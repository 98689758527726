import './MultiViewBulletPoint.css';

const MultiViewBulletPoint = ({ point, imgUrl }) => {
    return (
        <div className="multi-view-bullet-point">
            <img src={imgUrl} alt="bullet point img" />
            <span>{point}</span>
        </div>
    );
};

export default MultiViewBulletPoint;
